<template lang="pug">
tr
  // Player -> GameLog
  sticky-column(
    style="width: 100px; min-width: 100px",
    :title="'日期'",
    :offset="0",
    v-if="category == 'GameLog'"
  )
  sticky-column.bdr2(
    style="width: 80px; min-width: 80px",
    :title="'對手'",
    :offset="100",
    v-if="category == 'GameLog'"
  )
  th(v-if="category == 'GameLog'") 主/客
  th(v-if="category == 'GameLog'") 勝負
  th(v-if="category == 'GameLog'") 比分
  th.bdr(v-if="category == 'GameLog'") 時間

  // Player -> Season
  sticky-column.bdr2(
    style="width: 120px; min-width: 120px",
    :offset="0",
    v-if="category == 'Season'"
  )
  th(v-if="category == 'Season'") 出賽
  th.bdr(v-if="category == 'Season'") 時間

  // Team -> Statistics
  sticky-column.bdr2(
    style="width: 100px; min-width: 100px",
    :offset="0",
    v-if="category == 'Statistics'"
  )
  th.bdr(v-if="category == 'Statistics'") 場次

  th 得分
  th 籃板
  th 助攻
  th 抄截
  th.bdr 阻攻
  th 命中
  th 出手
  th.bdr 命中率
  th 命中
  th 出手
  th.bdr 命中率
  th 命中
  th 出手
  th.bdr 命中率
  th 命中
  th 出手
  th.bdr 命中率
  th 進攻
  th.bdr 防守
  th 失誤
  th 犯規
  th +/-
  th EFF
  th A/T
</template>

<script>
import StickyColumn from "@/components/common/Table/StickyColumn";
export default {
  name: "BottomHead",
  props: {
    category: {
      type: String,
      required: true,
    },
  },
  components: {
    StickyColumn,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";

#sticky-nav thead th {
  position: sticky;
  top: 38px;
}
</style>
