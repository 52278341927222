<template lang="pug">
.chart_wrapper
  highcharts.hc(
    :options="chartOptions",
    style="width: 500px; height: 400px; margin-left: 30px; margin-right: 30px"
  )
</template>

<script>
export default {
  name: "HighChartPolar",
  props: {
    title: String,
    personalList: Array,
    personalPercentList: Array,
    averageList: Array,
    averagePercentList: Array,
    bestList: Array,
    personalSeriesTitle: String,
  },
  data() {
    var vm = this;
    return {
      chartOptions: {
        chart: {
          polar: true,
        },
        title: {
          text: null,
          x: -80,
        },
        pane: {
          size: "80%",
        },
        xAxis: {
          categories: ["得分", "籃板", "助攻", "%罰球", "%三分", "%兩分"],
          tickmarkPlacement: "on",
          lineWidth: 0,
          labels: {
            formatter: function () {
              return vm.personalList[this.pos] + " " + this.value;
            },
          },
        },
        yAxis: {
          gridLineInterpolation: "polygon",
          lineWidth: 0,
          min: 0,
          max: 100,
          tickInterval: 20,
          labels: {
            format: " ",
          },
        },
        tooltip: {
          shared: true,
          formatter: function () {
            let text = this.points[0].x + "<br/>";

            text =
              text +
              "聯盟平均 :" +
              "<b>" +
              vm.averageList[this.points[0].point.index] +
              "</b>";

            switch (this.points[0].point.index) {
              case 0:
                text = text + "分" + "<br/>";
                break;
              case 1:
                text = text + "個" + "<br/>";
                break;
              case 2:
                text = text + "次" + "<br/>";
                break;
              case 3:
                text = text + "%" + "<br/>";
                break;
              case 4:
                text = text + "%" + "<br/>";
                break;
              case 5:
                text = text + "%" + "<br/>";
                break;
            }

            text =
              text +
              "聯盟最佳 :" +
              "<b>" +
              vm.bestList[this.points[0].point.index] +
              "</b>";

            switch (this.points[0].point.index) {
              case 0:
                text = text + "分" + "<br/>";
                break;
              case 1:
                text = text + "個" + "<br/>";
                break;
              case 2:
                text = text + "次" + "<br/>";
                break;
              case 3:
                text = text + "%" + "<br/>";
                break;
              case 4:
                text = text + "%" + "<br/>";
                break;
              case 5:
                text = text + "%" + "<br/>";
                break;
            }

            text =
              text +
              vm.personalSeriesTitle +
              ":" +
              "<b>" +
              vm.personalList[this.points[0].point.index] +
              "</b>";
            switch (this.points[0].point.index) {
              case 0:
                text = text + "分" + "<br/>";
                break;
              case 1:
                text = text + "個" + "<br/>";
                break;
              case 2:
                text = text + "次" + "<br/>";
                break;
              case 3:
                text = text + "%" + "<br/>";
                break;
              case 4:
                text = text + "%" + "<br/>";
                break;
              case 5:
                text = text + "%" + "<br/>";
                break;
            }
            return text;
          },
          // pointFormat: "<span>{series.name}: <b>{point.y:,.0f}%</b><br/>",
        },
        // legend: {
        //   enabled: false,
        //   align: "right",
        //   verticalAlign: "top",
        //   y: 70,
        //   layout: "vertical",
        // },
        // colors: [
        //   "#E6ECF1",
        //   "rgba(0,0,0,0.5)",
        //   "#487AA4",
        //   "#7ea8ad",
        //   "#acc37e",
        //   "#0a5d66",
        //   "#f7a800",
        //   "#b8a999",
        //   "#d2ddbb",
        //   "#706259",
        // ],
        series: [
          {
            type: "area",
            name: "聯盟平均",
            data: this.averagePercentList,
            pointPlacement: "on",
            color: "rgba(72,122,165,0.15)",
            fillOpacity: 0.15,
          },
          {
            type: "line",
            name: this.personalSeriesTitle,
            data: this.personalPercentList,
            pointPlacement: "on",
            color: "#487AA4",
          },
        ],
        exporting: {
          enabled: false, //是否顯示打印/導出
        },
        credits: {
          enabled: false, //是否顯示網址
        },
      },
    };
  },
  // methods: {
  //   barClick(event) {
  //     this.$emit("barClick", event.point.index);
  //   },
  // },
  // computed: {
  //   options() {
  //     let options = Object.assign(this.chartOptions, {});
  //     options.series[0].data = [];
  //     for (let i in this.barData) {
  //       options.series[0].data.push(this.barData[i]);
  //       // options.series[0].data[i].tooltip.valueSuffix = " %";
  //     }
  //     return options;
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.chart_wrapper {
  display: flex;
  justify-content: space-around;
}

p {
  text-align: center;
}

table {
  margin: auto;
  border: 1px solid #ccc;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 12px;
  margin-left: 30px;
  margin-right: 30px;
}

td,
th {
  border: 1px solid #ccc;
  padding: 4px 20px;
}
</style>
