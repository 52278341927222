<template lang="pug">
tr.hover(@click="rowClick(item)")
  sticky-column.bdl.bdr2(
    style="width: 120px; min-width: 120px",
    :offset="0",
    :title="title"
  )
  td(v-if="showTeam") {{ item.info.team_name }}
  td {{ item.info.record_matches }}
  td.bdr(v-if="showTime") {{ getMinutes(item.box.avg_secs) }}

  td(:class="[item.box.avg_pts > 0 ? 'blue_heavy' : 'gray_out_heavy']") {{ item.box.avg_pts.toFixed(1) }}
  td(:class="[item.box.avg_reb > 0 ? 'blue_heavy' : 'gray_out_heavy']") {{ item.box.avg_reb.toFixed(1) }}
  td(:class="[item.box.avg_ast > 0 ? 'blue_heavy' : 'gray_out_heavy']") {{ item.box.avg_ast.toFixed(1) }}
  td(:class="[item.box.avg_stl > 0 ? 'blue_heavy' : 'gray_out_heavy']") {{ item.box.avg_stl.toFixed(1) }}
  td.bdr(:class="[item.box.avg_blk > 0 ? 'blue_heavy' : 'gray_out_heavy']") {{ item.box.avg_blk.toFixed(1) }}

  td(:class="[item.box.avg_two_pt_fgm > 0 ? '' : 'gray_out']") {{ item.box.avg_fgm.toFixed(1) }}
  td(:class="[item.box.avg_two_pt_fga > 0 ? '' : 'gray_out']") {{ item.box.avg_fga.toFixed(1) }}
  td.bdr(:class="[item.box.avg_two_pt_fg_percent > 0 ? '' : 'gray_out']") {{ (item.box.avg_fg_percent * 100).toFixed(1) + '%' }}

  td(:class="[item.box.avg_two_pt_fgm > 0 ? '' : 'gray_out']") {{ item.box.avg_two_pt_fgm.toFixed(1) }}
  td(:class="[item.box.avg_two_pt_fga > 0 ? '' : 'gray_out']") {{ item.box.avg_two_pt_fga.toFixed(1) }}
  td.bdr(:class="[item.box.avg_two_pt_fg_percent > 0 ? '' : 'gray_out']") {{ (item.box.avg_two_pt_fg_percent * 100).toFixed(1) + '%' }}

  td(:class="[item.box.avg_three_pt_fgm > 0 ? '' : 'gray_out']") {{ item.box.avg_three_pt_fgm.toFixed(1) }}
  td(:class="[item.box.avg_three_pt_fga > 0 ? '' : 'gray_out']") {{ item.box.avg_three_pt_fga.toFixed(1) }}
  td.bdr(:class="[item.box.avg_three_pt_fg_percent > 0 ? '' : 'gray_out']") {{ (item.box.avg_three_pt_fg_percent * 100).toFixed(1) + '%' }}

  td(:class="[item.box.avg_ftm > 0 ? '' : 'gray_out']") {{ item.box.avg_ftm.toFixed(1) }}
  td(:class="[item.box.avg_fta > 0 ? '' : 'gray_out']") {{ item.box.avg_fta.toFixed(1) }}
  td.bdr(:class="[item.box.avg_ft_percent > 0 ? '' : 'gray_out']") {{ (item.box.avg_ft_percent * 100).toFixed(1) + '%' }}

  td(:class="[item.box.avg_off_reb > 0 ? '' : 'gray_out']") {{ item.box.avg_off_reb.toFixed(1) }}
  td.bdr(:class="[item.box.avg_def_reb > 0 ? '' : 'gray_out']") {{ item.box.avg_def_reb.toFixed(1) }}

  td(:class="[item.box.avg_tov > 0 ? '' : 'gray_out']") {{ item.box.avg_tov.toFixed(1) }}
  td(:class="[item.box.avg_pf > 0 ? '' : 'gray_out']") {{ item.box.avg_pf.toFixed(1) }}
  td(:class="[item.box.avg_plus_minus > 0 ? '' : 'gray_out']") {{ item.box.avg_plus_minus.toFixed(1) }}
  td(:class="[item.box.avg_eff > 0 ? '' : 'gray_out']") {{ item.box.avg_eff.toFixed(1) }}
  td.bdr(:class="[item.box.avg_at_ratio > 0 ? '' : 'gray_out']") {{ item.box.avg_at_ratio.toFixed(1) }}
</template>

<script>
import StickyColumn from "@/components/common/Table/StickyColumn";
export default {
  name: "Box",
  props: {
    item: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    showTeam: {
      type: Boolean,
      default: false,
    },
    showTime: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    StickyColumn,
  },
  data() {
    return {};
  },
  methods: {
    rowClick(item) {
      this.$emit("rowClick", item);
    },
    getMinutes(temp) {
      let min = parseInt(temp / 60);
      let second = (temp % 60).toFixed(0);
      if (second < 10) {
        return min + ":0" + second;
      }
      return min + ":" + second;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
</style>
